	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Slider functionality
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/

import  './vendor/flickity.min';

(function($) {

	$('.events-slider').each(function () {
        let _this = $(this);
        var args = {
            pageDots: false,
            wrapAround: true,
            cellSelector: 'li.events-list',
            percentPosition: true,
            contain: true,
            setGallerySize: true,
            resize: true,
            imagesLoaded: true,
            cellAlign: 'center',
            freeScroll: false,
            prevNextButtons: true,
            autoPlay: 4000,
            selectedAttraction: 0.008,
            friction: 0.16,
            adaptiveHeight: true
        };

        var $carousel = _this.flickity(args);
        $carousel.flickity(args);

	});
})( jQuery );