console.log('🥑 %cMade by Monk', 'background: #616a2e; color: #f4e9e2; padding: 5px 17px; border-radius: 3px;');
console.log(' %chttps://monk.com.au ', 'padding: 5px 13px;');

import './skip-link-focus-fix';
import './smoothscroll';
import './navigation';
import './scrollyclasses';
import './viewport-height';
import './fw-video';
import './sliders';
import './accordions';
import './fancybox';
import './gsapanims';

jQuery(function ($) {

	//page
	var $hamburger = $(".hamburger"),
		$site = $("body"),
		$menu = $(".main-navigation"),
		$menuitems = $(".menu-item"),
		$screenOverlay = $(".screen-overlay");

	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Mobile Menu
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	$hamburger.on("click", function () {
		$hamburger.toggleClass("is-active");
		$site.toggleClass("menu-open");
		// if ( $('body').hasClass('menu-open') ) {
		// 	$('#mobile-menu .label').text('Close')
		// } else {
		// 	$('#mobile-menu .label').text('Menu')
		// }
	});

	//close menu with an outside click (basically anywhere on .site-content)
	function closeMenu() {
		$site.removeClass("menu-open");
		$menu.removeClass("toggled");
		$menuitems.removeClass('toggled-on');
		$hamburger.removeClass("is-active");
	}

	$screenOverlay.on('click', closeMenu);

	$(document).on('keydown', function (e) {
		if (e.which == 27) {
			closeMenu();
		}
	});

	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Animate elements in on scroll
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	// $('.animate').on('inview', function (event, isInView) {
	// 	if (isInView) {
	// 		$(this).addClass('fadein');
	// 	}
	// });
	// AOS.init();


    $('.accordion-slider--item[data-count="1"]').addClass('active');
    $('.accordion-slider--item').on('mouseenter', function(){
        if ( !$(this).hasClass('active') ) {
            $('.accordion-slider--item.active').removeClass('active');
            $(this).addClass('active');
        } 
    });
    $('.accordion-slider--item').on('click', function(){
        if ( !$(this).hasClass('active') ) {
            $('.accordion-slider--item.active').removeClass('active');
            $(this).addClass('active');
        } else {
            $(this).removeClass('active');
            $('.accordion-slider--item[data-count="1"]').addClass('active');
        }
    });

    // function accordion(el) {

    //     const $items    = $('li', el);
    //     const setActive = el => {
                            
    //         el.style.setProperty('--panel-height', el.scrollHeight + 'px');

    //         $items.removeClass('active');

    //         el.classList.add('active');

    //     };

    //     $items.on('click', 'button', e => {

    //         e.preventDefault();

    //         setTimeout( () => setActive(e.currentTarget.parentElement) );

    //     });

    //     media.on('0', function() {

    //         requestAnimationFrame( () => {

    //             el.style.setProperty('--accordion-width', el.clientWidth + 'px');

    //             el.classList.add('initialized');

    //         } );

    //     });

    //     setActive( $items.get(0) );

    // }

    // function accordionSliderResize() {
    //     var parentHeight = $('.accordion-slider--list').height(); // Get the height of the parent element
    //     $('.accordion-slider--list').find('.accordion-slider--wrapper').css('max-height', parentHeight - 40 + 'px'); // Set max-height for child elements
    // }


    // $(window).on('resize', function() {

    //     accordionSliderResize();

    // });

});